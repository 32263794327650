.main-content-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
    padding: 24px;
}

.box__container{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--neutral-grey-100);
    background: var(--secondary-white);

    /* Untilted */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}


.box__container__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    gap: 12px;
    width: 100%;
}

.box__container__row.wrap{
    flex-wrap: wrap;
}


/* CARD SECTION */
.card_analytics {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 16px;
    border-radius: 12px;
    width: 100%;
    position: relative;
}

.card_analytics__icon{
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 0px;
    border-radius: 48px;
    background: var(--secondary-white);
}

.card_analytics__content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.card_analytics__content__row{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
}

.card_analytics__content__row svg{
    width: 80px;
    height: 40px;
}

.card_analytics__content .value{
    font-size: 38px;
    font-weight: 500;
}

.card_analytics__content__percentage{
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--secondary-white);
}

.percentage{
    font-size: 13px;
    font-weight: 600;
}

.row_gap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    width: 100%;
    margin: 0px;
    line-height: 1.5;

    color: var(--neutral-grey-500);
}

.card_analytics__content__percentage svg{
    width: 16px;
    height: 16px;
}

.card_analytics__tooltip_absolute {
    position: absolute;
    right: 0px;
    top: 15px;
}

/* BOOKING SECTION */
.box__container.booking{
    gap: 12px;
}

.booking_title{
    color: #272B30;
    font-family: 'RedHatDisplay';
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 111.111% */
    letter-spacing: -1.08px;
}
.booking_subtitle{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 100%;
}
.booking__content__percentage{
    display: flex;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--secondary-white);
    color: var(--neutral-grey-300);
}

.booking_subtitle > p{
    color: var(--neutral-grey-400);
}

.box__container__row__chart{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
}

.box__container__row__chart .box__container__row__column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}



/* CHART SECTION */

.chartLine{
    width: 100%;
    height: 100%;
    min-height: 350px;
}

.analytics__filters{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    width: 100%;
    position: relative;
}

.analytics__filters__calendar{
    position: absolute;
    left: 0px;
    top: 55px;
    z-index: 9999;

    display: none;
}
.analytics__filters__calendar.open{
    display: block;
}


/* ------------- MEDIA QUERIES ------------- */
@media screen and (max-width: 991px){
    .box__container__row{
        flex-direction: column;
    }

    .box__container{
        box-shadow: none;
        border: none;
        padding: 0 16px;
    }
}