.tag{
    display: flex;
    padding: 2px 10px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 16px;
    mix-blend-mode: multiply;
    width: fit-content;
    max-width: 180px;
}

.tag p{
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
}

.tag.active,
.tag.succeeded,
.tag.progress,
.tag.complete,
.tag.compensation-paid,
.tag.completed{background: var(--success-green-50);}

.tag.active p,
.tag.succeeded p,
.tag.progress p,
.tag.complete p,
.tag.compensation-paid p,
.tag.completed p{color: var(--success-green-100);}

.tag.review,
.tag.archived,
.tag.processing,
.tag.refunded,
.tag.compensation,
.tag.not-completed,
.tag.waiting-for-compensation,
.tag.booked{background: var(--warning-yellow-50);}

.tag.review p,
.tag.archived p,
.tag.processing p,
.tag.refunded p,
.tag.compensation p,
.tag.not-completed p,
.tag.booked p{color: var(--warning-yellow-900);}

.tag.lost,
.tag.error,
.tag.ended,
.tag.canceled,
.tag.pastDate,
.tag.reversed,
.tag.user-cancelled,
.tag.cancelledForReason,
.tag.disabled,
.tag.cancelled{background: var(--error-red-50); border: none!important;}

.tag.lost p,
.tag.error p,
.tag.ended p,
.tag.canceled p,
.tag.pastDate p,
.tag.reversed p,
.tag.user-cancelled p,
.tag.cancelledForReason p,
.tag.disabled p,
.tag.cancelled p{color: var(--error-red-100);}

.tag.pending,
.tag.draft,
.tag.inactive,
.tag.waiting,
.tag.requires_payment_method,
.tag.requires_confirmation,
.tag.requires_action,
.tag.requires_capture,
.tag.in-progress,
.tag.unused,
.tag.waiting-date{background: var(--pending-blue-50);}

.tag.pending p,
.tag.draft p,
.tag.inactive p,
.tag.waiting p,
.tag.requires_payment_method p,
.tag.requires_confirmation p,
.tag.requires_action p,
.tag.requires_capture p,
.tag.in-progress p,
.tag.unused p,
.tag.waiting-date p{color: var(--pending-blue-100);}

.tag.waiting-for-compensation,
.tag.waiting-for-transfer{
    background: var(--blue_50);
}

.tag.waiting-for-compensation p,
.tag.waiting-for-transfer p{
    color: var(--blue_500);
}



/* BOOKINGS TABLE TAGS*/
.bookings-table-tags{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:25px;
}

.tag-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.tag-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    border-radius: 15px;
    padding: 2px 7px;

    font-size: 12px;
    font-weight: 500;
    font-family: Poppins, sans-serif;
}

.tag-icon.green{background: var(--success-green-50); color: var(--success-green-100);}
.tag-icon.red{background: var(--error-red-50); color: var(--error-red-100);}
.tag-icon.grey{background: var(--neutral-grey-50); color: var(--neutral-grey-500);}
