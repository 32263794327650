.container-partner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    gap: 40px;
    padding: 24px;
    width: 100%;

    max-width: 450px;
    margin: 0 auto;
}


.partner-main-infos{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 24px;
}

.partner-activities{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    gap: 24px;
}

.partner-main-logo{
    width: 100%;
    height: 85px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.big_icon_logo{
    background-color: var(--neutral-grey-200);
    border-radius: 15px;
    height: 85px;
    width: 85px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
}

.partner-main-logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.social-media{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 18px;
    width: 100%;
}

.social-media a{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 50px;
    height: 50px;
    border-radius: 15px;
    padding: 8px;
    text-decoration: none;

    border: 1px solid var(--neutral-grey-300);
    background-color: #fff;
}

.social-media a:hover{
    background-color: #f5f5f5;
}

.social-media a img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.partner-activities-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
}


.partner-activities-list{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    gap: 12px;
}

.activity-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 12px;
    width: 100%;
    padding: 12px;
    border-radius: 15px;
    background-color: #fff
}

.activity-card-image{
    width: 93px;
    min-width: 93px;
    border-radius: 10px;
    background-color: var(--neutral-grey-50);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.activity-card-infos{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 3px;
    width: 100%;
}

.type-tag{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    padding: 3px 8px;
    border-radius: 15px;
    background-color: var(--neutral-grey-50);
    border: 1px solid var(--neutral-grey-200);
    font-size: 10px;

    width: auto;
}

.container-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 0px;
}

.activity-card-infos .location{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.activity-card-infos .location p{
    color: var(--neutral-grey-500);
}

.activity-card-infos .location img{
    width: 15px;
    height: 14px;
    object-fit: contain;
}

.big_icon{
   font-size: 35px;
}