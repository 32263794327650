.bg__modal{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}
.bg__modal.open{
    display: flex;
}
.modal{
    display: none;
    width: fit-content;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    min-width: 300px;
    max-height: 300px;

    border-radius: 10px;
    border: 1px solid var(--neutral-grey-200);
    background: var(--secondary-white);
    box-shadow: 0px 0.8px 1.5px 0px rgba(16, 24, 40, 0.06), 0px 0.8px 2.4px 0px rgba(16, 24, 40, 0.10);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    overflow: hidden;

    z-index: 999;
}

.addActivity{
    max-width: 800px;
    width: 100%;
    max-height: none;
}

.modal.search{
    position: absolute;
    top: 20%;
    left: 95%;
    transform: translate(-95%, -20%);
}

.modal.offer{
    padding: 0;
    gap: 0;
    max-height: 350px;
    min-width: 250px;
}

.modal.deleting{
    max-width: 500px;
    width: 100%;
    max-height: 250px;
}

.modal.verification{
    max-width: 600px;
    width: 100%;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px ;
}

.modal.email{
    max-width: 600px;
    width: 100%;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
}
.modal.offerAdd{
    max-width: 550px;
    width: 100%;
    max-height: 300px;
    padding: 20px;
}

.modal.booking{
    max-width: 600px;
    width: 100%;
    height: 100%;
    max-height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal.booking.accept{
    max-height: 270px;
}

.offerAdd .modal__header__col{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.booking .modal__header__col{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.offerAdd .modal__header__text{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    max-width: 450px;
}

.booking .modal__header__text{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;

    margin-top: 10px;
}

.offerAdd .title-16{
    max-width: 400px;
}

.email .modal__header,
.email .modal__content,
.email .modal__content__col{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal__header__row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
}

.modal__delete__icon img{
    width: 50px;
}

.modal.verifiedStatus{
    max-width: 400px;
    width: 100%;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px ;
}

.modal.refund{
    max-width: 700px;
    width: 100%;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    text-align: left;
}

.refund .modal__header{
    justify-content: center;
    align-items: center;
}
.refund .modal__content__div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    text-align: center;
}
.refund .modal__content__text.center{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.bg__modal.error{
    z-index: 99999;
}

.modal.open{
    display: flex;
}

.modal__header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    gap: 20px;
}

.modal__header.grey{
    border-bottom: 1px solid #EAECF0;
    background: #F9FAFB;
    padding: 15px 20px;
    align-items: center;
}

.modal__header.center{
    justify-content: center;
}

.modal__header p{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.modal__content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    height: 100%;
    overflow-y: hidden;

    padding: 0px 24px;
}

.center__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.center__container p{
    text-align: center;
}

.offer .modal__content{
    gap: 0!important;
}

.modal__content__row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.modal__content__list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;

    overflow-y: auto;
    max-height: 150px;
}

.offer_div{
    padding: 16px 24px;
    width: 100%;
    transition: all 0.2s ease-in-out;
    text-align: left;
}

.offer_div:hover{
    background: var(--neutral-grey-100);
    cursor: pointer;
}

.modal__content__list__item:not(:last-child){
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid var(--neutral-grey-200);
}

.modal__content__list__item:last-child{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding-bottom: 0;
    padding-top: 3px;
}

.modal__content__list__item:first-child{
    padding-top: 0;
}




.modal__content__item__avatar{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
}

.modal__close{
    cursor: pointer;
}

.modal__content__actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.verification .modal__header__col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.verification .modal__header__text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.verification .modal__header__text p{
    text-align: center;
    max-width: 90%;
}

/* IMAGE PREVIEW */
.imageBg{
    background: rgba(0, 0, 0, 0.6);
}
.imagePreview{
    max-height: 800px;
    min-width: 700px;
    border: none;
    box-shadow: none;

}
.imagePreview .modal__content__actions{
    justify-content: center;
    align-items: center;
}
.imagePreview .modal__content__image{
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 450px;
    display: flex;
    border-radius: 10px;
}

.imagePreview .modal__content__image img{
    width: 100%;
    border-radius: 10px;
    object-fit: contain;
}

.addActivity .modal__content{
    overflow-y: auto;
}
.addActivity .form__row{
    height: auto;
}

.addActivity .files-image-preview{
    width: 300px;
}

.addActivity .files-image-preview img{
    width: 100%;
    max-width: initial!important;
    object-fit: cover;
    border-radius: 10px;
}

.addActivity .files-image-preview{
    position: relative;
}

.cover-image-actions{
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

/* ------------- MEDIA QUERIES ------------- */
@media screen and (max-width: 991px){

    .bg__modal{
        height: 100dvh;
    }


    .booking_bottom{
        display: flex;
        width: 100vw;
        border-radius: 0;
        bottom: 0vh;
        max-height: none;
        top: initial;
        left: 0;
        transform: translate(0, 0);
    }

    .modal__content{
        gap: 25px;
        padding: 12px 24px;
    }

    .modal__header{
        justify-content: space-between;
        align-items: center;
    }

    .modal__colum{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 5px;
        width: 100%;
    }


    .modal__row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }

    .modal__actions{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .modal.booking{
        min-width: unset;
        max-width: unset;
        width: min-content;

        height: unset;
        max-height: unset;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .modal.booking .modal__header .modal__header__col{
        flex-direction: column;
        gap: 0;
    }

    .modal.booking .modal__content__actions{
        align-items: flex-start;
        justify-content: flex-start;
    }

    .modal.booking .modal__header .modal__header__col:nth-child(2){
        display: none;
    }

    .modal.booking .form__row.column{
        width: 0;
    }

    .modal.media-query{
        max-width: 90vw;
        width: 90vw;
        height: 100%!important;
        max-height: 70vh!important;
    }

    .modal.media-query .modal__header,
    .modal.media-query .modal__header__col,
    .modal.media-query .modal__header__text{
        width: 100%
    }

    .modal__colum__details{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }

    .modal__row__details__item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        width: 100%;
    }

    .modal__colum__details__item{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }


}


/*//support safari*/



