/* ------------- FONT-FACES ------------- */
/*poppins*/
@font-face {
    font-family: Poppins;
    src: url('../assets/fonts/poppins/Poppins-Regular.woff2') format('woff2'),
        url('../assets/fonts/poppins/Poppins-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: Poppins;
    src: url('../assets/fonts/poppins/Poppins-Medium.woff2') format('woff2'),
        url('../assets/fonts/poppins/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: Poppins;
    src: url('../assets/fonts/poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/poppins/Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: Poppins;
    src: url('../assets/fonts/poppins/Poppins-Bold.woff2') format('woff2'),
        url('../assets/fonts/poppins/Poppins-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

/*red hat display*/
@font-face {
    font-family: RedHatDisplay;
    src: url('../assets/fonts/red-hat-display/RedHatDisplay-Black.woff2') format('woff2'),
        url('../assets/fonts/red-hat-display/RedHatDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: RedHatDisplay;
    src: url('../assets/fonts/red-hat-display/RedHatDisplay-Bold.woff2') format('woff2'),
        url('../assets/fonts/red-hat-display/RedHatDisplay-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: RedHatDisplay;
    src: url('../assets/fonts/red-hat-display/RedHatDisplay-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/red-hat-display/RedHatDisplay-SemiBold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}



/* ------------- VARIABLES ------------- */

:root{
    --primary-yellow: #FECE2F;
    --primary-yellow-50: #FFFDF5;
    --primary-sea: #202236;
    --secondary-white: #FFFFFF;
    --secondary-black: #ODODOD;

    --neutral-dark-grey:#595C68;
    --neutral-grey:#BEBFC2;
    --neutral-mid-grey: #E6E8EC;
    --neutral-light-grey: #F1F2F3;
    --neutral-dark: #32354F;
    --neutral-grey-50: #F9FAFB;
    --neutral-grey-100: #F2F4F7;
    --neutral-grey-200: #EAECF0;
    --neutral-grey-300: #D0D5DD;
    --neutral-grey-400: #98A2B3;
    --neutral-grey-500: #667085;
    --neutral-grey-600: #475467;
    --neutral-grey-700: #344054;
    --neutral-grey-800: #20222C;
    --neutral-grey-900: #101828;

    --success-green-100: #027A48;
    --success-green-50: #ECFDF3;

    --error-red-100: #ff4d42;
    --error-red-700: #B42318;
    --error-red-300: #FDA29B;
    --error-red-600: #D92D20;
    --error-red-80: #FEF3F2;
    --error-red-50: #ffedec;
    --error-red-25: #FFFBFA;

    --warning-yellow-900: #DA8D1A;
    --warning-yellow-700: #FFC400;
    --warning-yellow-200: #FFE797;
    --warning-yellow-100: #fbf7dc;
    --warning-yellow-50: #FFFCEC;
    --warning-yellow-25: #FFFDF5;

    --pending-blue-100: #344054;
    --pending-blue-50: #F2F4F7;

    --blue_50: #EFF8FF;
    --blue_500: #5c8df3;
    --blue_700: #175CD3;

    --border-grey: #EAECF0;
    --btn-color: #040320;

    --typography-black: #20222C;
    --border-color:#20222C12;

    --icon-partners: url('../assets/icones/global/analytic.svg');
    --icon-company: url('../assets/icones/global/store.svg');
    --icon-logout: url('../assets/icones/global/logout.svg');
    --icon-settings: url('../assets/icones/global/settings.svg');
    --icon-support: url('../assets/icones/global/message-chat.svg');
    --icon-verification: url('../assets/icones/global/check-verified.svg');
    --icon-users: url('../assets/icones/global/users.svg');
    --icon-dashboard: url('../assets/icones/global/building.svg');
    --icon-analytics: url('../assets/icones/global/analytic.svg');
    --icon-manager: url('../assets/icones/global/edit_full.svg');
    --icon-booking: url('../assets/icones/global/coupon_full.svg');
    --icon-stripe: url('../assets/icones/global/bank.svg');
    --icon-bookings: url('../assets/icones/global/code-browser.svg');
}


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Poppins, sans-serif;
}

html,
body,
#root{
    width: 100%;
    min-height:100dvh;
    height: 100vh;
    padding-bottom: 50px;
}

.App{
    width: 100%;
    height: 100vh;
    min-height:100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.main{
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: var(--secondary-white);
    position: relative;
}

.main-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px 32px;
    gap: 25px;
    height: fit-content;
    min-height: 100vh;
    position: relative;
}

.container__grey{
    display: flex;
    width: 100%;
    padding: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 21px;
    border: 1px solid var(--border-grey, #E5E5E5);
    background: var(--secondary-white, #FFFFFF);
}

.container__grey.center{
    justify-content: center;
    align-items: center;
}

.main-container.center{
    justify-content: center;
    align-items: center;
}

.main-container > div{
    width: 100%;
}

.main-container__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}

.container__empty{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 200px;

    padding: 32px;

    border-radius: 10px;
    border: 1px solid var(--border-grey, #E5E5E5);
    background: var(--secondary-white, #FFFFFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    position: relative;
}

.sidebar_nav{
    height: inherit;
    min-width: 280px;
}

.center__container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.is__grey{
    background: var(--neutral-grey-50);
}

.is__error{
    background: var(--error-red-50);
    color: var(--error-red-100);
    border: 1px solid var(--error-red-100);

    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    border-radius: 8px;
    padding: 8px 16px;

    width: 100%;
    text-align: center;
}

.is__warning{
    background: #FFF6D9;
    color: var(--primary-yellow);
    border: 1px solid #FFB800;

    border-radius: 8px;
    padding: 8px 16px;
    margin-top: 10px;

    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.warning_text{
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    color: var(--primary-sea);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
}

.warning_text .bold{
    font-weight: 600;
}

/* ------------- FLEX / GRID ------------- */

.flex_row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.flex_col{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.grid_2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
    width: 100%;
}


/* ------------- TYPOGRAPHIE ------------- */
.large-30{
    font-family: RedHatDisplay, sans-sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    color: var(--primary-sea);
}

.large-25{
    font-family: RedHatDisplay, sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 900;
    color: var(--primary-sea);
}

h1{
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-sea);
}

h2{
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-sea);
}

h3{
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-sea);
}

h4{
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-sea);
}

h5{
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-sea);
}

.title-25{
    font-family: Poppins, sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    color: var(--primary-sea);
}

.title-23{
    font-family: Poppins, sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    color: var(--primary-sea);
}

.title-20{
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    color: var(--primary-sea);
}
.title-18{
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--primary-sea);
}

.title-16{
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: var(--primary-sea);
}

.title-14{
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: var(--primary-sea);
}

.title-12{
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: var(--primary-sea);
}

.title-10{
    font-family: Poppins, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    color: var(--primary-sea);
}

.text-20{
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    color: var(--primary-sea);
}

.text-18{
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    color: var(--primary-sea);
}

.text-16{
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: var(--primary-sea);
}
.text-15{
    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: var(--primary-sea);
}

.text-14{
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--primary-sea);
}

.text-12{
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: var(--primary-sea);
}

.text-10{
    font-family: Poppins, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    color: var(--primary-sea);
}

.bold{
    font-weight: 500;
}

.light{
    font-weight: 400;
}

.lighter{
    font-weight: 300;
}

.grey{
    color: var(--neutral-grey-400);
}

.underline{
    font-weight: 500;
    text-decoration: underline;
    text-decoration-color: var(--primary-yellow);
    /*underline height*/
    text-underline-offset: 5px;
    cursor: pointer;
}

.is__yellow{
    color: var(--primary-yellow);
}

.is__red{
    color: var(--error-red-100);
}

.center{
    text-align: center;
}

a{
    text-decoration: none;
    font-weight: 600;
    color: var(--secondary-black);
}

p.pointer{
    cursor: pointer!important;
}

/* ------------- FORM ------------- */
form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

/* ------------- LIST ------------- */

.list{
    width: 100%;
}

/* ------------- IMAGE ------------- */
.frimity_loop{
    position: absolute;
    bottom: -160px;
    right: 0;
    width: 500px;
    height: 100%;
}


.stripe-nocomplete{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 32px;
    max-width: 600px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* ------------- MEDIA QUERIES ------------- */
@media screen and (max-width: 991px){
    .App{
        flex-direction: column;
    }

    .main-container{
        padding: 90px 0px;
    }
}

